import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DayFormView from './DayFormView';
import timesheet_store from '../../../redux/TimeSheetStore';
import { getProjectById } from '../../../helpers/dataparsers';
import { getAssignments, getEmployeeId } from '../../../redux/Selectors';
import { 
  weelkIndexStringArr,
  weekDaysLowerArr,
  assignmentObj,
  dayDataObj,
  projectParsedObj
} from '../../../helpers/models';
import formMergeProps from './formHelpers/formMergeProps';
import { useFormValues } from './formHelpers/formHooks';

const DayFormEdit = ({
  weekIndex,
  dayName,
  back,
  projectId,
  projectData,
  dayData,
  assignments,
  employeeId,
  accessToken,
  submit
}) => {

  const formIntValues = {
    project: projectId,
    ...projectData.days[dayName]
  };

  const [ formValues, setFormValue ] = useFormValues(formIntValues);
  const { 
    projectValue,
    startValue,
    endValue,
    breakValue,
    commentsValue,
    totalTime
  } = formValues;

  const isEditing = (formIntValues.start && formIntValues.start !== '' && formIntValues.end !== '');

  useEffect(() => {
    const { projectName } = getProjectById(projectValue, assignments);

    // test if new project is leave, eg "! Annual Leave"
    if (/^!/.test(projectName) && !isEditing) {
      // if leave then force times
      setFormValue('start', '09:00');
      setFormValue('end', '17:00');
      setFormValue('break', '0');
      setFormValue('comments', '');
    }
  }, []);

  const handleSubmit = () => {
    const formValues = {
      formBreakTime: `${breakValue}`,
      formComment: commentsValue.trim(),
      formEndTime: endValue,
      formStartTime: startValue,
      formTotalTime: totalTime
    };
    submitEdit(formValues);
  }

  const handleReset = () => {
    const formValues = {
      formBreakTime: '0',
      formComment: '',
      formEndTime: '00:00',
      formStartTime: '00:00',
      formTotalTime: '0'
    };
    submitEdit(formValues);
  }

  const submitEdit = formValues => {
    const { clientId, weekEndingDateId } = projectData;
    const payload = {
      submitValues: {
        metaClientId: clientId,
        metaCurrentDay: dayName,
        metaEmployeeId: employeeId,
        metaProjectId: projectValue,
        metaWeekEndingDateId: weekEndingDateId,
        ...formValues
      },
      weekIndex,
      back,
      accessToken
    };

    submit(payload);
  }

  return (
    <DayFormView
      assignments={assignments}
      weekIndex={weekIndex}
      dayName={dayName}
      dayData={dayData}
      back={back}
      projectId={projectId}
      isProjectEditable={false}
      isEditing={isEditing}
      values={formValues}
      onFieldChange={setFormValue}
      onSubmit={handleSubmit}
      onReset={handleReset}
    />
  );
}

DayFormEdit.DisplayName = 'DayFormEdit';
DayFormEdit.prototype = {
  weekIndex: PropTypes.oneOf(weelkIndexStringArr).isRequired,
  dayName: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  projectId: PropTypes.number.isRequired,
  back: PropTypes.string.isRequired,
  projectData: PropTypes.shape(projectParsedObj),
  weekEndingDateId: PropTypes.number.isRequired,
  assignments: PropTypes.arrayOf(assignmentObj).isRequired,
  employeeId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  dayData: PropTypes.shape(dayDataObj).isRequired,
  submit: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth_store, timesheet_store }) => ({
  assignments: getAssignments(timesheet_store),
  weekSummaryData: timesheet_store.weekSummaryData,
  weekProjectSummaryData: timesheet_store.weekProjectSummaryData,
  employeeId: getEmployeeId(timesheet_store),
  accessToken: auth_store.accessToken
});

const mapDispatchToProps = dispatch => ({
  submit: payload => dispatch(timesheet_store.actions.SUBMIT_EDIT_DAY(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  formMergeProps
)(DayFormEdit);
