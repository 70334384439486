import React from "react";

function SessionError() {
  //* Reload window
  const reloadSession = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="ui segment placeholder">
        <div className="ui header icon red">
          <i className="ui icon warning circle" />
          An error occured trying to communicate with the server or attempting
          to login
        </div>
        <div className="inline">
          <div className="ui button" onClick={() => reloadSession()}>
            Try again
          </div>
        </div>
      </div>
    </>
  );
}

//# ============= DEBUG PERFORMANCE PROFILING =============
if (process.env.REACT_APP_HOST_ENV === "debug") {
  //~ Notify in console in debug env if there are any avoidable re-renders
  SessionError.whyDidYouRender = true;
}

export default SessionError;
