import axios from 'axios';
import Config from '../configs/Config';

const getAssignmentsApi = async accessToken =>
  axios({
    method: 'get',
    url: Config.ServerEndpoint + '/GetAssignments',
    headers: {
      Authorization: Config.MSAuthHeaderPrefix + accessToken
    }
  });

export default getAssignmentsApi;
