import React from "react";

const Value = ({ name, isNull = false, children }) => {
  const valueClassName = `value-${name}`;
  const fullClassName = isNull ? `${valueClassName} ${valueClassName}--null` : valueClassName;
  return (<span className={fullClassName}>{children}</span>);
}

export const formatTime = value => (value === "")
  ? <Value name="time" isNull={true}>--:--</Value>
  : <Value name="time">{value}</Value>;

export const formatHours = value => (value === 0)
  ? <Value name="hours" isNull={true}>0.00</Value>
  : <Value name="hours">{value.toFixed(2)}</Value>;

export const formatHoursLabel = value => (value === 0)
  ? <Value name="hours" isNull={true}>0.00 <span>hrs</span></Value>
  : <Value name="hours">{value.toFixed(2)} <span>hrs</span></Value>;
