import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { formatHoursLabel } from '../../../helpers/formatters';
import { weelkIndexArr } from '../../../helpers/models';

const ProjectView = ({
  weekIndex,
  projectId,
  projectName,
  clientName,
  weekLoggedProject
}) => (
  <li key={`project-${projectId}`} className="summary-list__item project-item">
    <Link
      to={`/project/${weekIndex}/${projectId}`}
      className="summary-list__content summary-list__content--link"
    >
      <div>
        <div className="project-item__project-name">{projectName}</div>
        <div className="project-item__client-name">{clientName}</div>
      </div>
      <div className="project-item__hrs">{formatHoursLabel(weekLoggedProject)}</div>
    </Link>
  </li>
);
ProjectView.displayName = 'ProjectView';
ProjectView.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  weekLoggedProject: PropTypes.number.isRequired
};

export default ProjectView;
