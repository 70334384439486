import axios from 'axios';
import Config from '../configs/Config';

//* Post data to /PostEditDayProjectSummary
const postEditDayProjectSummaryApi = async (accessToken, submitValues) =>
  axios({
    method: 'post',
    url: Config.ServerEndpoint + '/PostEditDayProjectSummary',
    headers: {
      Authorization: accessToken
    },
    data: submitValues
  });

export default postEditDayProjectSummaryApi;
