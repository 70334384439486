import React from "react";
import Config from "../../../configs/Config";

const SubmitHoursButton = ({ weekData, expectedUserHours, onSubmit }) => {
  const { weekLogged, timesheetStatus } = weekData;
  const isValid = (weekLogged >= expectedUserHours);

  if (timesheetStatus === Config.timesheetAvailableTimeSheetStatus.draft) {
    return (
      <div className="actions">
        <button
          type="button"
          className="btn"
          disabled={!isValid}
          onClick={onSubmit}
        >Submit week</button>
      </div>
    );
  }

  return null;
}

export default SubmitHoursButton;
