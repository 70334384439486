import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import DayProject from '../Common/DayProject';
import NavLink from '../Common/NavLink';
import { getHoursLoggedTxt } from '../../../helpers/dataparsers';
import { weelkIndexStringArr, weekDaysLowerArr, projectParsedObj } from '../../../helpers/models';

dayjs.extend(advancedFormat);

const DaySummaryView = ({
  weekIndex,
  dayName,
  dateString,
  totalLogged,
  isEditable,
  daySummaryModel
}) => {
  const date = dayjs(dateString);
  const dayIndex = weekDaysLowerArr.indexOf(dayName);
  const weekIndexInt = parseInt(weekIndex);
  let backDayUrl;
  let forwardDayUrl;

  // work out previous day URL
  if (dayIndex !== 0) {  // not Monday, so just back one day
    backDayUrl = `/day/${weekIndex}/${weekDaysLowerArr[dayIndex - 1]}/nav`;
  } else if (weekIndexInt !== 0) { // not week 0, so back one week
    backDayUrl = `/day/${weekIndexInt - 1}/${weekDaysLowerArr[6]}/nav`;
  } else { // Monday of week 0, disable link
    backDayUrl = '';
  }

  // work out next day URL
  if (dayIndex !== 6) { // not Sunday, so just forward one day
    forwardDayUrl = `/day/${weekIndex}/${weekDaysLowerArr[dayIndex + 1]}/nav`;
  } else if (weekIndexInt !== 4) { // not week 4, so forward one week
    forwardDayUrl = `/day/${weekIndexInt + 1}/${weekDaysLowerArr[0]}/nav`;
  } else { // Sunday of week 4, disable link
    forwardDayUrl = '';
  }

  return (
    <section className="content">
      <header className="content__header page-header">
        <div className="page-header__top">

          <NavLink 
            url={backDayUrl} 
            icon="left" 
            label="Previous day"
          />

          <div className="page-title">
            <h1 className="page-title__main">
              <span className="page-title__strong">{date.format("dddd")}</span>
              <span className="page-title__weak">{date.format("Do MMMM, YYYY")}</span>
            </h1>
            <span className="page-title__sub">{getHoursLoggedTxt(totalLogged)}</span>
          </div>

          <NavLink 
            url={forwardDayUrl} 
            icon="right" 
            label="Next day"
          />

        </div>
      </header>

      <ul className="summary-list">
        {daySummaryModel.map((project, index) => (
          <DayProject
            key={`project-${index}`}
            weekIndex={weekIndex}
            weekDay={dayName}
            projectData={project}
            isDayView={false}
            isEditable={isEditable}
          />
        ))}
      </ul>

      {(daySummaryModel.length === 0) && <div className="page-notice">No projects</div>}

      <div className="actions">
        <Link to={`/week/${weekIndex}`} className="btn btn--back">Back</Link>
        {isEditable && <Link to={`/day/${weekIndex}/${dayName}/add`} className='btn'>Add Project</Link>}
      </div>
    </section>
  );
}
DaySummaryView.displayName = 'DaySummaryView';
DaySummaryView.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexStringArr).isRequired,
  dayName: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  dateString: PropTypes.string.isRequired,
  totalLogged: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  daySummaryModel: PropTypes.arrayOf(PropTypes.shape(projectParsedObj)).isRequired
};

export default DaySummaryView;
