import axios from 'axios';
import Config from '../configs/Config';

const getIsUserAuthenticatedApi = async accessToken =>
  axios({
    method: 'get',
    url: Config.ServerEndpoint + '/GetIsUserAuthenticated',
    headers: {
      Authorization: Config.MSAuthHeaderPrefix + accessToken
    }
  })

export default getIsUserAuthenticatedApi;
