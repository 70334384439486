import { ofType } from 'redux-observable';
import { forkJoin, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { getCurrentUserDetailApi, getAssignmentsApi } from '../../api';
import handleError from './handleError';
import { timesheetActions } from '../TimeSheetStore';

const getUserDataEpic = actions$ => actions$.pipe(
  ofType('timesheet_store/GET_USER_DATA'),

  map(({ payload }) => ({ accessToken: payload })),

  mergeMap(({ accessToken }) => forkJoin(
    from(getCurrentUserDetailApi(accessToken)),
    from(getAssignmentsApi(accessToken))
  ).pipe(
    map(([ userDetails, assignments ]) => timesheetActions.SET_USER_DATA(
      { userDetails: userDetails.data, assignments: assignments.data }
    )),

    catchError(handleError)
  )
));

export default getUserDataEpic;
