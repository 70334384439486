import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DayFormView from './DayFormView';
import timesheet_store from '../../../redux/TimeSheetStore';
import { getProjectById } from '../../../helpers/dataparsers';
import { getAssignments, getEmployeeId } from '../../../redux/Selectors';
import { 
  weelkIndexStringArr,
  weekDaysLowerArr,
  assignmentObj,
  dayDataObj
} from '../../../helpers/models';
import formMergeProps from './formHelpers/formMergeProps';
import { useFormValues } from './formHelpers/formHooks';

const DayFormAddProject = ({
  weekIndex,
  dayName,
  dayData,
  weekEndingDateId,
  projectId,
  assignments,
  isNewWeek,
  employeeId,
  accessToken,
  submit
}) => {
  const formIntValues = {
    break: 0,
    comments: '',
    date: '',
    end: '',
    start: '',
    project: projectId
  };

  const [ formValues, setFormValue ] = useFormValues(formIntValues);
  const { 
    projectValue,
    startValue,
    endValue,
    breakValue,
    commentsValue,
    totalTime
  } = formValues;

  useEffect(() => {
    const { projectName } = getProjectById(projectValue, assignments);

    // test if new project is leave, eg "! Annual Leave"
    if (/^!/.test(projectName)) {
      // if leave then force times
      setFormValue('start', '09:00');
      setFormValue('end', '17:00');
      setFormValue('break', '0');
      setFormValue('comments', '');
    }
  }, []);
  
  const handleSubmit = () => {
    const { projectName } = getProjectById(projectValue, assignments);

    const payload = {
      submitValues: {
        metaCurrentFormProjectId: projectValue,
        metaEmployeeId: employeeId,
        metaWeekEndingDateId: weekEndingDateId,
        metaCurrentDay: dayName,
        formCurrentProjectName: projectName,
        formBreakTime: `${breakValue}`,
        formComment: commentsValue.trim(),
        formEndTime: endValue,
        formStartTime: startValue,
        formTotalTime: totalTime
      },
      weekIndex,
      back: 'project',
      accessToken
    };

    submit(payload);
  }

  return (
    <DayFormView
      assignments={assignments}
      weekIndex={weekIndex}
      dayName={dayName}
      dayData={dayData}
      back={'project'}
      projectId={projectId}
      isProjectEditable={false}
      isEditing={false}
      values={formValues}
      onFieldChange={setFormValue}
      onSubmit={handleSubmit}
    />
  );
}

DayFormAddProject.DisplayName = 'DayFormAddProject';
DayFormAddProject.prototype = {
  weekIndex: PropTypes.oneOf(weelkIndexStringArr).isRequired,
  dayName: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  weekEndingDateId: PropTypes.number.isRequired,
  isNewWeek: PropTypes.bool.isRequired,
  assignments: PropTypes.arrayOf(assignmentObj).isRequired,
  employeeId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  dayData: PropTypes.shape(dayDataObj).isRequired,
  currentProjectIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  submit: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth_store, timesheet_store }) => ({
  assignments: getAssignments(timesheet_store),
  weekSummaryData: timesheet_store.weekSummaryData,
  weekProjectSummaryData: timesheet_store.weekProjectSummaryData,
  employeeId: getEmployeeId(timesheet_store),
  accessToken: auth_store.accessToken
});

const mapDispatchToProps = dispatch => ({
  submit: payload => dispatch(timesheet_store.actions.SUBMIT_ADD_DAY(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  formMergeProps
)(DayFormAddProject);
