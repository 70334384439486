import React from "react";

const FIELD_ID = "field-comments";

const FieldComments = ({ value, onChange }) => (
  <div className="field">
    <label htmlFor={FIELD_ID} className="field__label">Comments (optional)</label>
    <textarea
      id={FIELD_ID}
      className="field__input field__input--textarea"
      value={value}
      onChange={onChange}
    />
  </div>
)

export default FieldComments;
