import React from 'react';
import PropTypes from 'prop-types';
import DayView from './DayView';
import WeekHeader from '../Common/WeekHeader';
import SubmitHoursButton from '../Common/SubmitHoursButton';
import SubmitHoursModal from '../Common/SubmitHoursModal';
import { weekDataToArray } from '../../../helpers/dataparsers';
import { convertSQLDate } from '../../other/Dates';
import { weelkIndexArr } from '../../../helpers/models';

const WeekSummaryView = ({
  weekIndex,
  weekData,
  expectedUserHours,
  onSubmit
}) => (
  <section className="content">

    <WeekHeader
      weekIndex={weekIndex}
      type="week"
      startingDate={convertSQLDate(weekData.weekStartingDate)}
      endingDate={convertSQLDate(weekData.weekEndingDate)}
      totalHours={weekData.weekLogged}
      timesheetStatus={weekData.timesheetStatus}
      weekData={weekDataToArray(weekData)}
      expectedUserHours={expectedUserHours}
    />

    <ul className="summary-list summary-list--borderless">
      {weekDataToArray(weekData).map(({ day, date, logged }) => (
        <DayView key={`day-${day}`} weekIndex={weekIndex} day={day} date={date} logged={logged} />
      ))}
    </ul>

    <SubmitHoursButton
      weekData={weekData}
      expectedUserHours={expectedUserHours}
      onSubmit={onSubmit}
    />

    <SubmitHoursModal weekIndex={weekIndex} />

  </section>
);
WeekSummaryView.displayName = 'WeekSummaryView';
WeekSummaryView.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  expectedUserHours: PropTypes.number.isRequired,
  weekData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default WeekSummaryView;
