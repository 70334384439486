import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  errorMsg: '',
  isModalShown: false
};

const PageStateStore = createSlice({
  //~ Slice name
  name: 'pagestate_store',

  initialState,

  //~ Reducers
  reducers: {

    SHOW_MODAL: state => {
      state.isModalShown = true;
    },

    HIDE_MODAL: state => {
      state.isModalShown = false;
    },

    RESET: state => initialState
  }
});

export const { actions: pageStateActions } = PageStateStore;
export default PageStateStore;
