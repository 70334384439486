import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getDateHeading } from '../../other/Dates';
import NavLink from './NavLink';
import { weelkIndexArr, weekDataObj } from '../../../helpers/models';
import WeekChart from './WeekChart';

const WeekHeader = ({
  weekIndex,
  type,
  startingDate,
  endingDate,
  totalHours,
  timesheetStatus,
  weekData,
  expectedUserHours
 }) => {
  const backWeekIndex = weekIndex - 1;
  const forwardWeekIndex = weekIndex + 1;

  const getLinkUrl = targetIndex => (targetIndex < 0 || targetIndex > 4)
    ? '' : `/${type}/${targetIndex}`;

  const renderTab = (tabType, label, icon) => (
    (tabType === type)
      ? <span className="page-header__tab page-header__tab--active"><i className={`${icon} icon`} /> {label}</span>
      : <Link to={`/${tabType}/${weekIndex}`} className="page-header__tab"><i className={`${icon} icon`} /> {label}</Link>
  );

  return (
    <header className="content__header page-header">
      <div className="page-header__top">
        <NavLink url={getLinkUrl(backWeekIndex)} icon="left" label="Previous week" />
        <div className="page-header__title page-title">
          <h1 className="page-title__main">{getDateHeading(startingDate, endingDate)}</h1>
        </div>
        <NavLink url={getLinkUrl(forwardWeekIndex)} icon="right" label="Next week" />
      </div>

      <WeekChart
        weekData={weekData}
        totalHours={totalHours}
        timesheetStatus={timesheetStatus}
        weekIndex={weekIndex}
        expectedUserHours={expectedUserHours}
      />

      <div className="page-header__tabs">
        {renderTab('week', 'Week View', 'calendar')}
        {renderTab('projects', 'Projects View', 'industry')}
      </div>
    </header>
  );
}
WeekHeader.displayName = 'WeekHeader';
WeekHeader.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  type: PropTypes.oneOf([ 'week', 'projects' ]).isRequired,
  startingDate: PropTypes.instanceOf(Date).isRequired,
  endingDate: PropTypes.instanceOf(Date).isRequired,
  totalHours: PropTypes.number.isRequired,
  timesheetStatus: PropTypes.string.isRequired,
  weekData: PropTypes.arrayOf(PropTypes.shape(weekDataObj)).isRequired,
  expectedUserHours: PropTypes.number.isRequired
};

export default WeekHeader;
