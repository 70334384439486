import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import { formatTime, formatHoursLabel } from "../../../helpers/formatters";

const DateTitle = ({ date }) => {
  const thisDate = dayjs(date);
  return (
    <div className="day-project__name">
       <span>{thisDate.format("dddd")}</span>
       <span>{thisDate.format("Do MMMM")}</span>
     </div>
  );
};

const ProjectTitle = ({ projectName, clientName }) => (
  <div className="day-project__name">
    <span>{projectName}</span>
    <span>{clientName}</span>
  </div>
);

const DayProjectContent = ({
  isDayView,
  isEditable,
  projectData: { projectName, clientName },
  dayData: { date, start, end, break: breakHrs, hrs: totalHrs, comments }
}) => {
  // Make sure the comments aren't too long
  const commentsFull = comments || '';
  const maxCommentLength = 180;
  const isCommentTooLong = (commentsFull.length > maxCommentLength); 
  const trimmedComment = isCommentTooLong ? `${comments.substring(0, maxCommentLength)}…` : comments;
  
  return (
    <div className="day-project">
      <div className="day-project__header">
        {isDayView
          ? <DateTitle date={date} />
          : <ProjectTitle projectName={projectName} clientName={clientName} />
        }
        {isEditable && <div className="day-project__icon">
          <i className={classNames(
              'icon',
              {
                'edit': (totalHrs > 0),
                'add': (totalHrs === 0)
              }
            )} />
        </div>}
      </div>
      <div className="day-project__details">
        {(totalHrs > 0) &&
          <dl className="times-list">
            <dt className="times-list__label">Time</dt>
            <dd className="times-list__value">{formatTime(start)} to {formatTime(end)}</dd>

            <dt className="times-list__label">Break</dt>
            <dd className="times-list__value">{formatHoursLabel(breakHrs)}</dd>

            <dt className="times-list__label">Total</dt>
            <dd className="times-list__value">{formatHoursLabel(totalHrs)}</dd>
          </dl>
        }
        {(commentsFull.trim() !== '') &&
          <div className="day-project__comments">
            {trimmedComment}
          </div>
        }
      </div>
    </div>
  )}
;

const ProjectLink = ({ projectId, isEditable, isProject, isNewProject, weekIndex, weekDay, children }) => {
  // Not project, create link to form via day URL
  if (isEditable && !isProject) {
    return (
      <Link to={`/day/${weekIndex}/${weekDay}/edit/${projectId}`}>
        {children}
      </Link>
    );
  }

  // Is project, create link to form via project URL
  if (isEditable && isProject) {
    const url = isNewProject
      ? `/project/${weekIndex}/${projectId}/add/${weekDay}`
      : `/project/${weekIndex}/${projectId}/edit/${weekDay}`;

    return (
      <Link to={url}>
        {children}
      </Link>
    );
  }

  // Not editable, just show without link
  return children;
}

const DayProject = ({ weekDay, weekIndex, projectData, isDayView, isEditable, isNewProject }) => {
  const dayData = projectData.days[weekDay];
  const { date: currentDate } = dayData;
  const { assignFrom, assignTo } = projectData;
  let isWithinAssignedDate = true;

  if (assignFrom && dayjs(currentDate).isBefore(dayjs(assignFrom), 'day')) {
    // project hasn't started by current date
    isWithinAssignedDate = false;
  } else if (assignTo && dayjs(currentDate).isAfter(dayjs(assignTo), 'day')) {
    // project has ended by current date
    isWithinAssignedDate = false;
  }

  const isLink = isEditable && isWithinAssignedDate;

  return (
    <li className="summary-list__item">
      <ProjectLink
        projectId={projectData.projectId}
        isEditable={isLink}
        isProject={isDayView}
        isNewProject={isNewProject}
        weekIndex={weekIndex}
        weekDay={weekDay}
      >
        <div className={classNames(
          'summary-list__content',
          { 'summary-list__content--link': isLink }
        )}>
          <DayProjectContent
            isDayView={isDayView}
            isEditable={isLink}
            projectData={projectData}
            dayData={dayData}
          />
        </div>
      </ProjectLink>
    </li>
  );
}

export default DayProject;
