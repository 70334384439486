import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { timesheetActions } from '../TimeSheetStore';
import { pageStateActions } from '../PageStateStore';

const setSignOutEpic = action$ => action$.pipe(
  ofType('auth_store/SET_SIGN_OUT'),

  mergeMap(() => [
    timesheetActions.RESET(),
    pageStateActions.RESET()
  ])
);

export default setSignOutEpic;
