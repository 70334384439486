import React, { useState } from "react";
import { connect } from "react-redux";
import Profile from './Profile';
import { ReactComponent as Logo } from '../authentication/dws_logo.svg';
import AuthStore from "../../redux/AuthStore";
import { isUserDetailsSet } from "../../redux/Selectors";

//* Pure component
const Header = ({ isUserDetailsSet, userDetails, logout, isLogoHidden = false }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleProfile = () => { setIsProfileOpen(!isProfileOpen) }

  return (
    <header className="header-banner">
      {!isLogoHidden ? <div className="header-banner__logo">
        <Logo className="header-banner__logo-img" />
      </div> : <div />}
      {isUserDetailsSet ? <div className="header-banner__profile">
        <button type="button" className="header-banner__profile-btn" onClick={() => { toggleProfile(); }}>
          <i className="user icon" />
        </button>
        <Profile isOpen={isProfileOpen} userDetails={userDetails} onSignOut={logout} />
      </div> : <div />}
    </header>
  );
};

const mapStateToProps = ({ timesheet_store }) => ({
  isUserDetailsSet: isUserDetailsSet(timesheet_store),
  userDetails: timesheet_store.currentUserDetail || {}
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthStore.actions.SET_SIGN_OUT())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

// export default Header;
