import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  FieldProject,
  FieldBreak,
  FieldComments,
  FieldTime
} from './fields';
import {
  weelkIndexStringArr,
  weekDaysLowerArr,
  assignmentObj,
  formValuesObj
} from '../../../helpers/models';

const DayFormView = ({
  assignments,
  weekIndex,
  dayName,
  dayData,
  projectId,
  back,
  isProjectEditable,
  isEditing,
  values,
  onFieldChange,
  onSubmit,
  onReset
}) => {
  const { 
    projectValue,
    startValue,
    endValue,
    breakValue,
    commentsValue,
    totalTime,
    isValidForm
  } = values;

  const renderTitle = () => {
    const date = dayjs(dayData.date);
    return (
      <h1 className="page-title__main">
        <span className="page-title__strong">{date.format("dddd")}</span>
        <span className="page-title__weak">{date.format("Do MMMM, YYYY")}</span>
      </h1>
    );
  }

  const onProjectChange = e => {
    onFieldChange('project', e.target.value);
  }

  const onStartChange = value => {
    onFieldChange('start', value);
  }

  const onEndChange = value => {
    onFieldChange('end', value);
  }

  const onBreakChange = e => {
    onFieldChange('break', e.target.value);
  }

  const onCommentsChange = e => {
    onFieldChange('comments', e.target.value);
  }

  let backLinkUrl;
  switch (back) {
    case 'day':
      backLinkUrl = `/day/${weekIndex}/${dayName}`;
      break;

    case 'week':
      backLinkUrl = `/week/${weekIndex}/`;
      break;

    default:
      backLinkUrl = `/project/${weekIndex}/${projectId}`;
  }

  return (
    <section className="content">
      <header className="content_header">
        <div className="page-title">
          {renderTitle()}
        </div>
      </header>

      <form>
        <div className="day-form">

          <FieldProject
            value={projectValue}
            options={assignments}
            isEditMode={isProjectEditable}
            currentDate={dayData.date}
            onChange={onProjectChange}
          />

          <div className="day-form__times">

            <FieldTime
              fieldId="field-start"
              fieldLabel="Start Time"
              value={startValue}
              onChange={onStartChange}
            />

            <FieldTime
              fieldId="field-end"
              fieldLabel="End Time"
              value={endValue}
              onChange={onEndChange}
            />

            <FieldBreak
              value={breakValue}
              onChange={onBreakChange}
            />

          </div>

          <FieldComments
            value={commentsValue}
            onChange={onCommentsChange}
          />

          <div>
            Total hours: {(parseInt(totalTime) > 0) ? totalTime : '0'}
          </div>

          <div className="actions">
            <Link to={backLinkUrl} className="btn btn--back">Back</Link>
            {isEditing &&
              <button
                type="button"
                className="btn btn--warning"
                onClick={onReset}
              >Remove</button>
            }
            <button
              type="button"
              className="btn"
              disabled={!isValidForm}
              onClick={onSubmit}
            >Save</button>
          </div>

        </div>

      </form>
    </section>
  );
}
DayFormView.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.shape(assignmentObj)).isRequired,
  weekIndex: PropTypes.oneOf(weelkIndexStringArr).isRequired,
  dayName: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  projectId: PropTypes.string,
  values: PropTypes.exact(formValuesObj).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func
};
DayFormView.defaultProps = {
  projectId: null,
  onReset: () => {}
};

export default DayFormView;
