export default {
  //! AD endpoint
  MicrosoftAuthClientId: "b10534bd-e794-4779-8493-a1c3b84ecf3f",

  //! Server endpoint
  ServerEndpoint: (process.env.NODE_ENV === 'development')
    ? "http://localhost:3001"
    : "/api/timesheet",

  //! Microsoft Authentication Prefix
  MSAuthHeaderPrefix: "Bearer ",

  //! Place to get our ip address
  IpEndpoint: "https://api.ipify.org",

  //! Banner Logo url
  BannerLogoUrl: "http://www.dws.com.au/wp-content/uploads/2019/04/logo.png",

  //! Days of the week
  DaysOfWeek: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ],

  //! Data Attributes
  timesheetDateAttributes: {
    date: "Date",
    startTime: "Start",
    endTime: "End",
    breakTime: "Break",
    hrs: "Hrs",
    comments: "Comments"
  },

  timesheetAvailableTimeSheetStatus: {
    submitted: "Submitted",
    draft: "Draft",
    none: "None"
  },

  //! Number of weeks retrieved
  ArrayLengthOfWeeksRetrieved: 4
};
