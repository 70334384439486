import { from } from 'rxjs';
import { switchMap, mergeMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import postEditDayProjectSummaryApi from '../../api/postEditDayProjectSummaryApi';
import postAddDayProjectSummaryApi from '../../api/postAddDayProjectSummaryApi';
import handleError from './handleError';
import { timesheetActions } from '../TimeSheetStore';

const submitRequest = (accessToken, submitValues, isAdd) => isAdd
  ? postAddDayProjectSummaryApi(accessToken, submitValues)
  : postEditDayProjectSummaryApi(accessToken, submitValues);

const submitDayEpic = action$ => action$.pipe(
  ofType('timesheet_store/SUBMIT_EDIT_DAY', 'timesheet_store/SUBMIT_ADD_DAY'),

  map(({ type, payload: { accessToken, weekIndex, submitValues, back } }) => ({
    accessToken,
    weekIndex,
    submitValues,
    back,
    projectId: submitValues.metaProjectId || submitValues.metaCurrentFormProjectId || 0,
    isAdd: (type === 'timesheet_store/SUBMIT_ADD_DAY')
  })),

  switchMap(({ accessToken, weekIndex, submitValues, isAdd, back, projectId }) =>
    from(submitRequest(accessToken, submitValues, isAdd)).pipe(

      map(() => ({
        accessToken,
        backUrl: (back === 'day') ? `/week/${weekIndex}` : `/project/${weekIndex}/${projectId}`
      })),

      mergeMap(({ accessToken, backUrl }) => [
        timesheetActions.UPDATE_WEEK_DATA(accessToken),
        push(backUrl)
      ]),

      catchError(handleError)
    )
  )
);

export default submitDayEpic;
