import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Loading state
  isLoadingUserData: true,
  isLoadingWeekData: true,
  loadingMessage: null,

  //* Week summary data
  weekSummaryData: null,
  weekProjectSummaryData: null,

  //* Current user data
  currentUserDetail: null,

  //* Assignment data
  assignmentData: null,
};

const TimeSheetStore = createSlice({
  //~ Slice name
  name: 'timesheet_store',

  initialState,

  //~ Reducers
  reducers: {
    GET_USER_DATA: state => {
      state.isLoadingUserData = true;
      state.loadingMessage = 'Fetching user data';
    },

    GET_WEEK_DATA: state => {
      state.isLoadingWeekData = true;
      state.loadingMessage = 'Fetching user data';
    },

    UPDATE_WEEK_DATA: state => {
      state.isLoadingWeekData = true;
      state.loadingMessage = 'Updating user data';
    },

    SET_USER_DATA: (state, { payload: { userDetails, assignments } }) => {
      state.isLoadingUserData = false;
      state.currentUserDetail = userDetails;
      state.assignmentData = assignments.map(
        assignment => ({
          ...assignment,
          billingId: parseInt(assignment.billingId),
          employeeId: parseInt(assignment.employeeId),
          officeId: parseInt(assignment.officeId),
          projectId: parseInt(assignment.projectId)
        })
      );
    },

    SET_WEEK_DATA: (state, { payload: { weekSummary, projectSummary } }) => {
      state.isLoadingWeekData = false;
      state.weekSummaryData = weekSummary;
      state.weekProjectSummaryData = projectSummary;
    },

    SUBMIT_ADD_DAY: (state, response) => {
      state.isLoadingWeekData = true;
      state.loadingMessage = 'Submitting timesheet';
    },

    SUBMIT_EDIT_DAY: (state, response) => {
      state.isLoadingWeekData = true;
      state.loadingMessage = 'Submitting timesheet';
    },

    SUBMIT_WEEK: (state, response) => {
      state.isLoadingWeekData = true;
      state.loadingMessage = 'Submitting week';
    },

    RESET: state => initialState
  }
});

export const { actions: timesheetActions } = TimeSheetStore;
export default TimeSheetStore;
