import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PieChart } from 'react-minimal-pie-chart';
import { weelkIndexArr, weekDataObj } from '../../../helpers/models';

const WeekChart = ({
  weekData,
  totalHours,
  weekIndex,
  timesheetStatus,
  expectedUserHours
}) => {
  const colours = {
    active: '#2db5be',
    done: '#7abe2d',
    error: '#be2d2d',
    remaining: '#e2ecec',
    errorRemaining: '#ece2e2'
  };
  // https://www.npmjs.com/package/react-minimal-pie-chart

  const isPast = weekIndex < 2;
  const isDone = totalHours >= expectedUserHours;
  const isSubmitted = timesheetStatus === 'Submitted';

  let chartData = weekData
    .filter(day => day.logged > 0)
    .map(({ day, logged }) => ({
      title: day,
      value: logged,
      color: isSubmitted ? colours.done :
        isPast ? colours.error : colours.active
    }));

  if (!isDone) {
    chartData.push({
      title: 'Remaining',
      value: expectedUserHours - totalHours,
      color: isPast ? colours.errorRemaining : colours.remaining
    })
  }

  return (
    <div className="week-chart">
  
      <div className="week-chart__graph">
        <PieChart
          data={chartData}
          lineWidth={30}
          paddingAngle={(chartData.length > 1) ? 2 : 0}
          startAngle={270}
          animate={false}
        />
      </div>

      <div className="week-chart__hours">
        <div
          className={classNames('week-chart__label', {
            'week-chart__label--done': isDone,
            'week-chart__label--error': (isPast && !isDone)
          })}
        >
          Hours
          {isDone && <i className="check icon" />}
          {(isPast && !isDone) && <i className="exclamation circle icon" />}
        </div>
        <div className="value-hours">
          {(totalHours === 0) ? <>None</> : <>{totalHours} / {expectedUserHours} <span>hrs</span></> }
        </div>
      </div>

      <div className="week-chart__hours">
        <div
          className={classNames('week-chart__label', {
            'week-chart__label--done': isSubmitted,
            'week-chart__label--error': (isPast && !isSubmitted)
          })}
        >
          State
          {isSubmitted && <i className="check icon" />}
          {(isPast && !isSubmitted) && <i className="exclamation circle icon" />}
        </div>
        {timesheetStatus}
      </div>
    </div>
  );
}
WeekChart.displayName = 'WeekChart';
WeekChart.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  weekData: PropTypes.arrayOf(PropTypes.shape(weekDataObj)).isRequired,
  totalHours: PropTypes.number.isRequired,
  timesheetStatus: PropTypes.string.isRequired,
  expectedUserHours: PropTypes.number.isRequired
};

export default WeekChart;
