// import { parseWeekData } from '../helpers/dataparsers';

// ============= TimeSheetStore =============

// return an array of projects for the current week
export const selectThisWeeksProjects = ({ weekProjectSummaryData, currentWeekIndex }) => {
  // let's map the weeks to currentWeekIndex
  const weekIndexMap = [
    'isCurrentWeekMinus2',
    'isCurrentWeekMinus1',
    'isCurrentWeek',
    'isCurrentWeekPlus1',
    'isCurrentWeekPlus2'
  ];

  return weekProjectSummaryData
    .filter(
      // filter out anything that isn't the active week (or no project set)
      ({ weekProjectSummaryModel }) =>
        !!weekProjectSummaryModel[weekIndexMap[currentWeekIndex]]
        && weekProjectSummaryModel.projectId !== null
        && weekProjectSummaryModel.weekLoggedProject > 0
    )
    .map(
      // return just an array of the weekProjectSummaryModel
      ({ weekProjectSummaryModel }) =>  weekProjectSummaryModel
    );
}

export const getExpectedUserHours = ({ currentUserDetail }) => parseInt(currentUserDetail.expectedHours);

export const getEmployeeId = ({ currentUserDetail }) => currentUserDetail.employeeId;

export const getAssignments = ({ assignmentData }) => assignmentData;

export const getAssignmentsGrouped = ({ assignmentData }) => {
  const groupReducer = (groups, assignment) => {
    if (!groups[assignment.clientName]) {
      groups[assignment.clientName] = {
        name: assignment.clientName,
        projects: []
      };
    }
    groups[assignment.clientName].projects.push(assignment);
    return groups;
  }

  const grouped = assignmentData.reduce(groupReducer, {});
  return Object.values(grouped)
}

export const isTimesheetLoading = ({ isLoadingUserData, isLoadingWeekData }) => isLoadingUserData || isLoadingWeekData;

export const isUserDetailsSet = ({ currentUserDetail }) => !!currentUserDetail;

const fieldCount = (counterObj, field, fieldRegex) => {
  const [fieldName, value] = field;
  console.log('test', fieldName, value);
  // const cleanVal = `${value || ''}`.trim();
  const cleanVal = '9:00';

  if (fieldRegex.test(fieldName) && cleanVal !== '') {
    if (cleanVal in counterObj) {
      counterObj[cleanVal]++;
    } else {
      counterObj[cleanVal] = 1;
    }
  }

  return counterObj;
}

const orderTimes = timesObj => Object.entries(timesObj)
  .sort((a, b) => b[1]-a[1])
  .map(([ time ]) => time)

export const getSuggestedTimes = ({ weekSummaryData }) => {
  let startTimesCount = {};
  let endTimesCount = {};

  // loop through each week
  weekSummaryData.forEach(({ daySummaryModel }) => {
    // loop through each entry in current week
    daySummaryModel.forEach(entry => {
      // loop through entry fields
      for (const field of Object.entries(entry)) {
        // add all start times to count
        startTimesCount = fieldCount(startTimesCount, field, /Start$/);
        // add all end times to count
        endTimesCount = fieldCount(endTimesCount, field, /End$/);
      }
    });
  });

  // array of times order by count
  return [ orderTimes(startTimesCount), orderTimes(endTimesCount)];
}

// ============= PageStateStore =============

export const getModalState = ({ isModalShown }) => isModalShown;
