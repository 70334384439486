
const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

const WEEK_INDEX_MAP = [
  'isCurrentWeekMinus2',
  'isCurrentWeekMinus1',
  'isCurrentWeek',
  'isCurrentWeekPlus1',
  'isCurrentWeekPlus2'
];

const DAY_FIELD_PATTERN = new RegExp(`^(${DAYS_OF_THE_WEEK.join("|")})(.+)`);

const weekDataToArray = weekData => DAYS_OF_THE_WEEK.map(
  day => ({
    day,
    date: weekData[`${day}Date`],
    logged: weekData[`${day}Logged`]
  })
);

const parseDaySummaryModel = data => data.map(parseWeekData);

const parseWeekSummaryData = data => data.map(parseWeekData);

const parseWeekData = data => {
  // create template for data with space for days
  let parsedData = {
    days: DAYS_OF_THE_WEEK.reduce(
      (obj, day) => {
        obj[day] = {};
        return obj;
      },
      {}
    )
  };

  for (let [key, value] of Object.entries(data)) {
    // lots of values with white-space in the data, lets clean that up
    let cleanValue = (typeof value === "string") ? value.trim() : value;

    // no null values
    if (cleanValue === null) {
      cleanValue = '';
    }

    // look for fields with weekdays in the key
    const match = key.match(DAY_FIELD_PATTERN);

    if (match) {
      // if weekday field: break up and append to day object
      parsedData.days[match[1]][match[2].toLowerCase()] = cleanValue;
    } else {
      // else if non-weekday field: just add
      parsedData[key] = cleanValue;
    }
  }

  return parsedData;
}

const getWeeksProjects = (weekProjectSummaryData, weekIndex) => (
  weekProjectSummaryData
    .filter(
      // filter out anything that isn't the active week (or no project set)
      ({ weekProjectSummaryModel }) =>
        !!weekProjectSummaryModel[WEEK_INDEX_MAP[weekIndex]]
        && weekProjectSummaryModel.projectId !== null
        && weekProjectSummaryModel.weekLoggedProject > 0
    )
    .map(
      // return just an array of the weekProjectSummaryModel
      ({ weekProjectSummaryModel }) =>  weekProjectSummaryModel
    )
);

const getTotalTime = (formStartTime = '', formEndTime = '', formBreakTime = '') => {
  const valueStart = formStartTime.split(":");
  const valueEnd = formEndTime.split(":");

  let timeStart = new Date().setHours(valueStart[0], valueStart[1], "00", 0);
  let timeEnd = new Date().setHours(valueEnd[0], valueEnd[1], "00", 0);

  let result = (
    (timeEnd - timeStart) / 36e5 -
    Math.abs(formBreakTime)
  ).toFixed(2);

  if (isNaN(result) || result === "" || result === null) {
    return '0';
  }

  return result;
};

const getHoursLoggedTxt = hours => {
  let hoursTxt;

  switch (parseInt(hours)) {
    case 0:
      hoursTxt = 'No hours logged';
      break;

    case 1:
      hoursTxt = '1 hour logged';
      break;

    default:
      hoursTxt = `${hours} hours logged`;
  }

  return hoursTxt;
};

const getProjectById = (id, projects, isFailSafe = true) => {
  const result = projects.filter(
    ({ projectId }) => `${id}` === `${projectId}`
  );

  if (!result && isFailSafe) {
    return { // No results in failsafe mode: return 'unknown' object
      assignFrom: '',
      assignTo: null,
      billingId: 0,
      clientName: 'Unknown Client',
      employeeId: 0,
      officeId: 0,
      projectId: id,
      projectName: 'Unknown Project',
      sortOrder: '1',
      submitStatus: 'f'
    };
  } else {
    return result[0] || false;
  }
}


export {
  weekDataToArray,
  parseDaySummaryModel,
  parseWeekSummaryData,
  parseWeekData,
  getWeeksProjects,
  getTotalTime,
  getHoursLoggedTxt,
  getProjectById
}
