import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const FieldTime = ({ fieldId, fieldLabel, value, onChange }) => {
  const inputHoursEl = useRef(null);
  const inputMinsEl = useRef(null);

  // break time value into hours and minutes
  const [ hrsValue, minsValue ] = (value || ':').split(':');

  // hours effects
  useEffect(() => {
    // if hours is two digits than focus on minutes
    if (hrsValue.length >= 2 && document.activeElement === inputHoursEl.current) {
      inputMinsEl.current.focus();
    }
  }, [hrsValue]);

  const onHoursChange = ({ target: { value } }) => {
    let newValue =  /^\d{0,2}$/.test(value) ? value : '';
    const valueInt = parseInt(newValue);

    // hours can't be more then 23
    if (valueInt > 23) {
      newValue = '23';
    }

    // if the first digit is more then 2 than assume it's zero padded (eg '09')
    if (valueInt > 2 && newValue.length === 1) {
      newValue = `0${newValue}`;
    }

    // setHours(newValue);
    onChange(`${newValue}:${minsValue}`);
  }

  const onHoursBlur = () => {
    // number should be zero padded
    if (hrsValue.length === 1) {
      onChange(`0${hrsValue}:${minsValue}`);
    }
  };

  const onMinChange = ({ target: { value } }) => {
    let newValue =  /^\d{0,2}$/.test(value) ? value : '';
    const valueInt = parseInt(newValue);

    // minutes can't be more then 59
    if (valueInt > 59) {
      newValue = '59';
    }

    // if the first digit is more then 5 than assume it's zero padded (eg '09')
    if (valueInt > 5 && newValue.length === 1) {
      newValue = `0${newValue}`;
    }

    // setMins(newValue);
    onChange(`${hrsValue}:${newValue}`);
  }

  const onMinsBlur = () => {
    // number should be zero padded
    if (minsValue.length === 1) {
      onChange(`${hrsValue}:0${minsValue}`);
    }

    // mins should not be blank if hours is set
    if (minsValue === '' && hrsValue !== '') {
      onChange(`${hrsValue}:00`);
    }
  };

  const onFocus = event => event.target.select();

  return (
    <div className="field">
      <label htmlFor={fieldId} className="field__label">{fieldLabel}</label>

        <div className="field__input field__input--time timepicker">
          <input 
            id={fieldId}
            className="timepicker__field timepicker__field--hrs"
            type="text"
            pattern="[0-9]*"
            autoComplete="off"
            value={hrsValue}
            ref={inputHoursEl}
            onChange={onHoursChange}
            onBlur={onHoursBlur}
            onFocus={onFocus}
          />
          <span className="timepicker__separator">:</span>
          <input 
            id={`${fieldId}-min`}
            className="timepicker__field timepicker__field--mins"
            type="text"
            pattern="[0-9]*"
            autoComplete="off"
            value={minsValue}
            ref={inputMinsEl}
            onChange={onMinChange}
            onBlur={onMinsBlur}
            onFocus={onFocus}
          />
          
        </div>
    </div>
  );
}
FieldTime.displayName = 'FieldTime';
FieldTime.propTypes = {
  fieldId: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FieldTime;
