import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { authActions } from '../../redux/AuthStore';

const Profile = ({ isOpen, userDetails: { fullname, email, officeName } }) => {
  const dispatch = useDispatch();
  const onSignOut = () => dispatch(authActions.SET_SIGN_OUT());

  return (
    <div className={classNames('profile', { 'profile--open': isOpen })}>
      <div className="profile__name">{fullname}</div>
      <div className="profile__email">{email}</div>
      <div className="profile__office">{officeName}</div>
      <div className="profile__actions">
        <a className="btn profile__btn" href="https://reporting.dws.com.au/Reports/report/My%20DWS/Consultants/Consultant_Dashboard">myDWS Dashboard</a>
        <button className="btn profile__btn" onClick={onSignOut}>Sign out</button>
      </div>
    </div>
  );
}
Profile.displayName = 'Profile';
Profile.propTypes = {
  isOpen: PropTypes.bool,
  userDetails: PropTypes.shape({
    fullname: PropTypes.string,
    email: PropTypes.string,
    officeName: PropTypes.string
  })
}
Profile.defaultProps = {
  isOpen: false
}

export default Profile;
