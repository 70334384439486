import { createSlice } from '@reduxjs/toolkit';

const initialState = {

  // Show loading message
  isLoggingIn: false,

  //* Status of logged in from `MicrosoftAuth` component
  isSignedIn: false,

  //* Access token received OAuth
  accessToken: null,

  //* Calling the server returned a 401 so session expired
  sessionExpired: false,

  //* There was an unexpected error
  unexpectedError: false
};

const AuthStore = createSlice({
  //~ Slice name
  name: "auth_store",

  initialState,

  //~ Reducers
  reducers: {

    GET_LOGIN: (state, { payload }) => {
      state.isLoggingIn = true;
      state.accessToken = payload;
    },

    //* Set user status to signed in
    SET_SIGN_IN: (state, response) => {
      state.isLoggingIn = false;
      state.isSignedIn = true;
    },

    //* Set user status to signed out
    SET_SIGN_OUT: state => initialState,

    SET_LOGGING_IN: state => {
      state.isLoggingIn = true;
    },

    //* Once session expired, it will redirect to session expired page and reload the whole application thus resetting the state
    SET_SESSION_EXPIRED: state => {
      state.isLoggingIn = false;
      state.sessionExpired = true;
    },

    //* Unexpected error occured, it will redirect to error page and reload whole application and reset the state
    SET_UNEXPECTED_ERROR: state => {
      state.isLoggingIn = false;
      state.unexpectedError = true;
    },

    //! DEBUGGING TOOLS ONLY
    ...(process.env.REACT_APP_HOST_ENV === "debug" && {
      DEBUG_TOGGLE_SIGN_IN_OUT: state => {
        state.isSignedIn = !state.isSignedIn;
      }
    }),

    ...(process.env.REACT_APP_HOST_ENV === "debug" && {
      DEBUG_TOGGLE_SESSION_EXPIRY: state => {
        state.sessionExpired = !state.sessionExpired;
      }
    }),

    ...(process.env.REACT_APP_HOST_ENV === "debug" && {
      DEBUG_TOGGLE_UNEXPECTED_ERROR: state => {
        state.unexpectedError = !state.unexpectedError;
      }
    })
  }
});

export const { actions: authActions } = AuthStore;
export default AuthStore;
