//# Date utility functions

//* Convert SQL date to Js Date
export const convertSQLDate = date => {
  const jsDate = date.split("T")[0].split(/[- :]/);
  return new Date(Date.UTC(jsDate[0], jsDate[1] - 1, jsDate[2]));
};

//* Get list of days between 2 dates
export const getDateArray = weekSummaryData => {
  let startDate = convertSQLDate(weekSummaryData.weekStartingDate);
  const endDate = convertSQLDate(weekSummaryData.weekEndingDate);

  let result = [];
  while (startDate <= endDate) {
    result.push({
      day: startDate.getDate(),
      month: startDate.toLocaleString("en-us", { month: "long" }),
      year: startDate.getUTCFullYear()
    });
    startDate.setDate(startDate.getDate() + 1);
  }

  return result;
};

//* Format date heading
export const getDateHeading = (startDate, endDate) => {
  const startMonth = startDate.toLocaleString("en-us", { month: "short" });
  const startMonthLong = startDate.toLocaleString("en-us", { month: "long" });
  const endMonth = endDate.toLocaleString("en-us", { month: "short" });

  return startMonth === endMonth
    ? `${startDate.getUTCDate()} - ${endDate.getUTCDate()} ${startMonthLong}, ${endDate.getUTCFullYear()}`
    : `${startDate.getUTCDate()} ${startMonth} - ${endDate.getUTCDate()} ${endMonth}, ${endDate.getUTCFullYear()}`;
};
