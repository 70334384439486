import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import DayProject from '../Common/DayProject';
import Config from '../../../configs/Config';
import { 
  weelkIndexArr,
  projectParsedObj
} from '../../../helpers/models';

dayjs.extend(advancedFormat);

const ProjectDetailView = ({ weekIndex, isEditable, projectWeekData, isNewProject }) => {
  const {
    projectName,
    clientName
  } = projectWeekData;

  return (
    <section className="content">
      <header className="content__header">
        <div className="page-title">
          <h1 className="page-title__main">
            <span className="page-title__strong">{projectName}</span>
          </h1>
          <span className="page-title__sub">{clientName}</span>
        </div>
      </header>

      <ul className="summary-list">
        {Config.DaysOfWeek.map((dayName) => {
          return (
            <DayProject
              key={`project-${dayName}`}
              weekIndex={weekIndex}
              weekDay={dayName.toLowerCase()}
              projectData={projectWeekData}
              isDayView={true}
              isEditable={isEditable}
              isNewProject={isNewProject}
            />
          );
        })}
      </ul>

      <div className="actions">
        <Link to={`/projects/${weekIndex}`} className="btn btn--back">Back</Link>
      </div>
    </section>
  );
}
ProjectDetailView.displayName = 'ProjectDetailView';
ProjectDetailView.prototype = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  projectWeekData: PropTypes.shape(projectParsedObj).isRequired,
  isEditable: PropTypes.bool.isRequired,
  isNewProject: PropTypes.bool.isRequired
};

export default ProjectDetailView;
