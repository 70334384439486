import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import ProjectSummaryView from './ProjectSummaryView';
import { pageStateActions } from '../../../redux/PageStateStore';
import { getExpectedUserHours } from '../../../redux/Selectors';
import { getWeeksProjects } from '../../../helpers/dataparsers';
import { 
  weelkIndexStringArr,
  weekProjectSummaryDataObj,
  weekSummaryDataObj,
  assignmentObj
} from '../../../helpers/models';

const ProjectSummaryContainer = ({
  match,
  weekProjectSummaryData,
  weekSummaryData,
  assignmentData,
  expectedUserHours,
  showModal
}) => {
  const weekIndex = parseInt(match.params.weekIndex);
  const weekProjects = getWeeksProjects(weekProjectSummaryData, weekIndex);
  const projectIds = weekProjects.map(project => project.projectId);
  const thisWeekSummaryModel = weekSummaryData[weekIndex].weekSummaryModel;
  const { weekStartingDate, weekEndingDate } = thisWeekSummaryModel;
  const unusedProjects = assignmentData.filter(
    assignment => (projectIds.indexOf(parseInt(assignment.projectId)) === -1)
  ).filter(
    ({ assignFrom, assignTo }) => {
      // make sure assignment is availble this week
      if (assignFrom && dayjs(weekEndingDate).isBefore(dayjs(assignFrom), 'day')) {
        // project hasn't started by end of the week
        return false;
      }

      if (assignTo && dayjs(weekStartingDate).isAfter(dayjs(assignTo), 'day')) {
        // project has ended by before the start of the week
        return false;
      }

      return true;
    }
  ).map(
    assignment => ({
      ...assignment,
      projectName: assignment.projectName.replace(/^! /, ''),
      weekLoggedProject: 0
    })
  );

  return (
    <ProjectSummaryView
      weekIndex={weekIndex}
      projects={[ ...weekProjects, ...unusedProjects ]}
      weekData={thisWeekSummaryModel}
      expectedUserHours={expectedUserHours}
      onSubmit={() => { showModal(); }}
    />
  );
}

ProjectSummaryContainer.displayName = 'ProjectSummaryContainer';
ProjectSummaryContainer.propTypes = {
  match: PropTypes.shape({
    weekIndex: PropTypes.oneOf(weelkIndexStringArr)
  }).isRequired,
  weekProjectSummaryData: PropTypes.arrayOf(
    PropTypes.shape(weekProjectSummaryDataObj)
  ).isRequired,
  weekSummaryData: PropTypes.arrayOf(
    PropTypes.shape(weekSummaryDataObj)
  ).isRequired,
  assignmentData: PropTypes.arrayOf(
    PropTypes.shape(assignmentObj)
  ).isRequired,
  expectedUserHours: PropTypes.number.isRequired,
  showModal: PropTypes.func.isRequired
};

const mapStateToProps = ({ timesheet_store }) => ({
  weekProjectSummaryData: timesheet_store.weekProjectSummaryData,
  weekSummaryData: timesheet_store.weekSummaryData,
  expectedUserHours: getExpectedUserHours(timesheet_store),
  assignmentData: timesheet_store.assignmentData
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch(pageStateActions.SHOW_MODAL())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSummaryContainer);
