import React from 'react';
import { connect } from 'react-redux';

import MicrosoftAuth from './authentication/MicrosoftAuth';
import Header from './header/Header';
import Timesheet from './timesheet/Timesheet';
import SessionExpired from './pages/SessionExpired';
import SessionError from './pages/SessionError';

import 'semantic-ui-css/semantic.min.css';
import '../styles/custom.css';
import '../styles/app.scss';

function App({ isSignedIn, sessionExpired, unexpectedError }) {
  /**
  //! Routing table:

  isSignedIn  |  storeSessionExpiry ||   ROUTE
  --------------------------------------------------------
       true        |       false         ||   /timesheet
       ---         |       true          ||   /sessionExpired
       false       |       false         ||   /

  unexpectedError => /error
  */

  //~ If unexpected error -> render unexpected error
  if (unexpectedError) {
    return (
      <>
        <Header />
        <div className="ui container">
          <SessionError />
        </div>
      </>
    );
  }

  //~ If store session expired -> render store session error
  if (sessionExpired) {
    return (
      <>
        <Header />
        <div className="ui container">
          <SessionExpired />
        </div>
      </>
    );
  }

  //~ If user is signed in -> render timesheet component
  if (isSignedIn && !sessionExpired) {
    return (
      <>
        <Header />
        <div className="ui container">
          <Timesheet />
        </div>
      </>
    );
  }

  //~ Default, show login screen
  return (
    <>
      <Header isLogoHidden={true} />
      <MicrosoftAuth />
    </>
  );
}

//# ============= REDUX =============
const mapStateToProps = ({ auth_store }) => ({
    isSignedIn: auth_store.isSignedIn,
    sessionExpired: auth_store.sessionExpired,
    unexpectedError: auth_store.unexpectedError
});

//# ============= DEBUG PERFORMANCE PROFILING =============
if (process.env.REACT_APP_HOST_ENV === "debug") {
  //~ Notify in console in debug env if there are any avoidable re-renders
  App.whyDidYouRender = true;
}

export default connect(mapStateToProps)(App);
