import { ofType } from 'redux-observable';
import { forkJoin, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { getWeekSummaryApi, getWeekProjectSummaryApi } from '../../api';
import handleError from './handleError';
import { timesheetActions } from '../TimeSheetStore';

const getWeekDataEpic = actions$ => actions$.pipe(
  ofType('timesheet_store/GET_WEEK_DATA', 'timesheet_store/UPDATE_WEEK_DATA'),

  map(({ payload }) => ({ accessToken: payload })),

  mergeMap(({ accessToken }) => forkJoin(
    from(getWeekSummaryApi(accessToken)),
    from(getWeekProjectSummaryApi(accessToken))
  ).pipe(
    map(([ weekSummary, projectSummary ]) => timesheetActions.SET_WEEK_DATA(
      { weekSummary: weekSummary.data, projectSummary: projectSummary.data }
    )),

    catchError(handleError)
  )

));

export default getWeekDataEpic;
