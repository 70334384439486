import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DayFormView from './DayFormView';
import timesheet_store from '../../../redux/TimeSheetStore';
import { getProjectById } from '../../../helpers/dataparsers';
import { getAssignments, getEmployeeId, getSuggestedTimes } from '../../../redux/Selectors';
import { 
  weelkIndexStringArr,
  weekDaysLowerArr,
  assignmentObj,
  dayDataObj
} from '../../../helpers/models';
import formMergeProps from './formHelpers/formMergeProps';
import { useFormValues } from './formHelpers/formHooks';

const DayFormAdd = ({
  weekIndex,
  dayName,
  dayData,
  weekEndingDateId,
  assignments,
  currentProjectIds,
  isNewWeek,
  employeeId,
  suggestedTimes,
  accessToken,
  submit
}) => {
  console.log('test me');
  const formIntValues = {
    break: 0,
    comments: '',
    date: '',
    end: '',
    start: '',
    project: ''
  };

  const [ formValues, setFormValue ] = useFormValues(formIntValues);
  const { 
    projectValue,
    startValue,
    endValue,
    breakValue,
    commentsValue,
    totalTime
  } = formValues;

  let isLeave = false;

  console.log('getSuggestedTimes', suggestedTimes);

  useEffect(() => {
    // test if new project is leave, eg "! Annual Leave"
    if (projectValue) {
      const { projectName } = getProjectById(projectValue, assignments);
      isLeave = /^!/.test(projectName);
    }

    if (isLeave) {
      // if leave then force times
      setFormValue('start', '09:00');
      setFormValue('end', '17:00');
      setFormValue('break', '0');
      setFormValue('comments', '');
    } else {
      setFormValue('start', formIntValues.start);
      setFormValue('end', formIntValues.end);
      setFormValue('break', formIntValues.break);
      setFormValue('comments', formIntValues.comments);
    }
  }, [projectValue]);
  
  const handleSubmit = () => {
    const { projectName } = getProjectById(projectValue, assignments);

    const payload = {
      submitValues: {
        metaCurrentFormProjectId: projectValue,
        metaEmployeeId: employeeId,
        metaWeekEndingDateId: weekEndingDateId,
        metaCurrentDay: dayName,
        formCurrentProjectName: projectName,
        formBreakTime: `${breakValue}`,
        formComment: commentsValue.trim(),
        formEndTime: endValue,
        formStartTime: startValue,
        formTotalTime: totalTime
      },
      weekIndex,
      back: 'day',
      accessToken
    };

    submit(payload);
  }

  // remove any of the current projects from the assignment options
  const removeUsedPropjects = ({ projectId }) => (currentProjectIds.indexOf(projectId) === -1)

  return (
    <DayFormView
      assignments={assignments.filter(removeUsedPropjects)}
      weekIndex={weekIndex}
      dayName={dayName}
      dayData={dayData}
      back={isNewWeek ? 'week' : 'day'}
      isProjectEditable={true}
      isEditing={false}
      values={formValues}
      onFieldChange={setFormValue}
      onSubmit={handleSubmit}
    />
  );
}

DayFormAdd.DisplayName = 'DayFormAdd';
DayFormAdd.prototype = {
  weekIndex: PropTypes.oneOf(weelkIndexStringArr).isRequired,
  dayName: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  weekEndingDateId: PropTypes.number.isRequired,
  isNewWeek: PropTypes.bool.isRequired,
  assignments: PropTypes.arrayOf(assignmentObj).isRequired,
  employeeId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  dayData: PropTypes.shape(dayDataObj).isRequired,
  currentProjectIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  submit: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth_store, timesheet_store }) => ({
  assignments: getAssignments(timesheet_store),
  weekSummaryData: timesheet_store.weekSummaryData,
  weekProjectSummaryData: timesheet_store.weekProjectSummaryData,
  employeeId: getEmployeeId(timesheet_store),
  accessToken: auth_store.accessToken,
  suggestedTimes: getSuggestedTimes(timesheet_store)
});

const mapDispatchToProps = dispatch => ({
  submit: payload => dispatch(timesheet_store.actions.SUBMIT_ADD_DAY(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  formMergeProps
)(DayFormAdd);
