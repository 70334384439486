import axios from 'axios';
import Config from '../configs/Config';

const postAddDayProjectSummaryApi = async (accessToken, submitValues) =>
  axios({
    method: 'post',
    url: Config.ServerEndpoint + '/PostAddDayProjectSummary',
    headers: {
      Authorization: accessToken
    },
    data: submitValues
  });

export default postAddDayProjectSummaryApi;
