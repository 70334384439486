import PropTypes from 'prop-types';

export const weelkIndexArr = [0, 1, 2, 3, 4];
export const weelkIndexStringArr = ['0', '1', '2', '3', '4'];
export const weekDaysLowerArr = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const weekDataObj = {
  date: PropTypes.string.isRequired,
  day: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  logged: PropTypes.number.isRequired
};

export const weekSummaryDataObj = {
  daySummaryModel: PropTypes.array.isRequired,
  weekSummaryModel: PropTypes.object.isRequired
}

export const weekProjectSummaryDataObj = {
  dayProjectSummaryModel: PropTypes.array.isRequired,
  weekProjectSummaryModel: PropTypes.object.isRequired
}

export const projectParsedObj = {
  clientId: PropTypes.number,
  clientName: PropTypes.string,
  days: PropTypes.object, // TODO: define
  employeeId: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  status: PropTypes.string,
  weekEndingDate: PropTypes.string,
  weekEndingDateId: PropTypes.number,
  weekStartingDate: PropTypes.string,
  weekTotalWork: PropTypes.number
}

export const assignmentObj = {
  employeeId: PropTypes.number,
  officeId: PropTypes.number,
  billingId: PropTypes.number,
  projectId: PropTypes.number,
  projectName: PropTypes.string,
  sortOrder: PropTypes.string,
  clientName: PropTypes.string,
  assignFrom: PropTypes.string,
  assignTo: PropTypes.string,
  submitStatus: PropTypes.string
}

export const formValuesObj = {
  projectValue: PropTypes.string.isRequired,
  startValue: PropTypes.string.isRequired,
  endValue: PropTypes.string.isRequired,
  breakValue: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
  commentsValue: PropTypes.string.isRequired,
  totalTime: PropTypes.string.isRequired,
  isValidForm: PropTypes.bool.isRequired
}

export const dayDataObj = {
  date: PropTypes.string.isRequired,
  logged: PropTypes.number.isRequired
}
