import React from 'react';
import dayjs from 'dayjs';
import { getProjectById } from '../../../../helpers/dataparsers';

const FIELD_ID = 'field-project';

const renderSelect = (value, options, currentDate, onChange) => {

  const groupOptions = options => {

    // remove all assignments that are outside the assigned date
    const filterByDate = ({ assignFrom, assignTo }) => {
      if (assignFrom && dayjs(currentDate).isBefore(dayjs(assignFrom), 'day')) {
        // project hasn't started by current date
        return false;
      }

      if (assignTo && dayjs(currentDate).isAfter(dayjs(assignTo), 'day')) {
        // project has ended by current date
        return false;
      }

      return true;
    }

    // group all assignments by client
    const groupReducer = (groups, assignment) => {
      if (!groups[assignment.clientName]) {
        groups[assignment.clientName] = {
          name: assignment.clientName,
          projects: []
        };
      }
      groups[assignment.clientName].projects.push(assignment);
      return groups;
    }

    const grouped = options.filter(filterByDate).reduce(groupReducer, {});
    
    return Object.values(grouped)
  }

  const renderProjectOptions = ({ name, projects }, index) => (
    <optgroup key={`project-group-${index}`} label={name}>
      {projects.map(({ projectId, projectName }) => (
        <option
          key={`project-option-${projectId}`}
          value={projectId}
        >{projectName}</option>
      ))}
    </optgroup>
  );

  return (
    <select
      id={FIELD_ID}
      value={value}
      className="field__input field__input--select"
      onChange={onChange}
    >
      <option value=""></option>
      {groupOptions(options).map(renderProjectOptions)}
    </select>
  );
}

const renderReadOnly = (value, options) => {
  const { projectName } = getProjectById(value, options);

  return (
    <input
      id={FIELD_ID}
      type="text"
      className="field__input field__input--readonly"
      value={projectName}
      readOnly={true}
    />
  )
}

const FieldProject = ({ value, options, isEditMode, currentDate, onChange }) => {
  const field = isEditMode
    ? renderSelect(value, options, currentDate, onChange)
    : renderReadOnly(value, options);

  return (
    <div className="field">
      <label className="field__label" htmlFor={FIELD_ID}>Project</label>
      {field}
    </div>
  );
}

export default FieldProject;
