import React from "react";
import { connect } from "react-redux";
import { getModalState } from "../../../redux/Selectors";

const modalRender = children => (
  <div className="modal">
    <div role="dialog" aria-modal="true" className="modal__content">
      {children}
    </div>
  </div>
);

const Modal = ({ isShown, children }) => isShown ? modalRender(children) : null;

const mapStateToProps = ({ page_state_store }) => ({
  isShown: getModalState(page_state_store)
});

export default connect(mapStateToProps)(Modal);
