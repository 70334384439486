import axios from 'axios';
import Config from '../configs/Config';

const postSubmitWeekTimesheetApi = async (accessToken, employeeId, weekEndingDateId) =>
  axios({
    method: 'post',
    url: Config.ServerEndpoint + '/PostSubmitWeekTimesheet',
    headers: {
      Authorization: accessToken
    },
    data: {
      metaEmployeeId: employeeId,
      metaWeekEndingDateId: weekEndingDateId
    }
  });

export default postSubmitWeekTimesheetApi;
