import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import is from "is_js";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import { store, history } from "./store";
import { ConnectedRouter } from 'connected-react-router'
// import MockDate from 'mockdate'; // NOTE: see below

//# If IE or  Opera other old devices redirect to old timesheet because react doesn't support it
if (is.ie() || is.opera()) {
  (window.location.href = "https://www.google.com/chrome/")();
}

//# If debugging, add some performance profiling
if (process.env.REACT_APP_HOST_ENV === 'debug' || process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);

  // so we don't need to change the mock data
  // NOTE; This plug is making redux tools bug out
  // MockDate.set('2020-07-16');
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/serviceWorker.js')
  .catch(function(error) {
    console.log('Service worker registration failed, error:', error);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
