import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import DaySummaryView from './DaySummaryView';
import { parseDaySummaryModel } from '../../../helpers/dataparsers'

const DaySummaryContainer = ({ match, weekSummaryData, isStrictNav }) => {
  const { weekIndex, dayName } = match.params;
  const { daySummaryModel, weekSummaryModel } = weekSummaryData[parseInt(weekIndex)];
  const isEditable = (weekSummaryModel.timesheetStatus !== 'Submitted');
  const totalLogged = weekSummaryModel[`${dayName}Logged`];
  const dateString = weekSummaryModel[`${dayName}Date`];
  
  if (daySummaryModel.length === 0 && isEditable && !isStrictNav) {
    return <Redirect to={`/day/${weekIndex}/${dayName}/add`} />;
  }

  return (
    <DaySummaryView
      weekIndex={weekIndex}
      dayName={dayName}
      dateString={dateString}
      totalLogged={totalLogged}
      isEditable={isEditable}
      daySummaryModel={parseDaySummaryModel(daySummaryModel)}
    />
  );
}

DaySummaryContainer.displayName = 'DaySummaryContainer';
DaySummaryContainer.propTypes = {
  match: PropTypes.shape({
    weekIndex: PropTypes.string,
    dayName: PropTypes.string
  }).isRequired,
  weekSummaryData: PropTypes.array.isRequired,
  isStrictNav: PropTypes.bool
};
DaySummaryContainer.defaultProps = {
  isStrictNav: false
}

const mapStateToProps = ({ timesheet_store }) => ({
  weekSummaryData: timesheet_store.weekSummaryData
});

export default connect(mapStateToProps)(DaySummaryContainer);
