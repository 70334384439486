import { of, iif } from 'rxjs';
import { authActions } from '../AuthStore';

const handleError = error => iif(
  () => (error && error.response && error.response.status === 401),
  of(authActions.SET_SESSION_EXPIRED()),
  of(authActions.SET_UNEXPECTED_ERROR())
);

export default handleError;
