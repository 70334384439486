import React from 'react';
import { connect } from 'react-redux';
import MicrosoftLogin from 'react-microsoft-login';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Config from '../../configs/Config';
import { authActions } from '../../redux/AuthStore';
import Spinner from '../timesheet/Spinner';
import { ReactComponent as Logo } from './dws_logo.svg';

//# Handles login using Microsoft OAuth
const MicrosoftAuth = ({ isLoading, getLogin, showLoading, setError}) => {
  // there is an issue with MicrosoftLogin showing errors before token is ready
  const ingoreErrors = [
    'Cannot read property \'accessToken\' of undefined',
    'undefined is not an object (evaluating \'t.authResponseWithAccessToken.accessToken\')',
    'Login_In_Progress: Error during login call - login is already in progress.'
  ];

  const authHandler = (error, data) => {
    showLoading();

    if (error && error.message) {
      console.error(error.message); // Always log error if there is one
    }

    if (error && ingoreErrors.indexOf(error.message) === -1) {
      setError();
    } else if (data && (data.accessToken || data.authResponseWithAccessToken.accessToken)) {
      const payload = data.authResponseWithAccessToken.accessToken || data.accessToken || '';
      getLogin(payload);
    }
  }

  if (isLoading) {
    return (
      <div className="login">
        <div className="login__loading">
          <Spinner label="Signing In" />
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">

          <div className="login">

            <div className="login__content">
              
              <Logo className="login__logo" />

              <h1 className="login__title">
                DWS
                <span>Group</span>
              </h1>

              <h2 className="login__subtitle">Timesheet System</h2>

              <MicrosoftLogin
                clientId={Config.MicrosoftAuthClientId}
                authCallback={(error, data) => authHandler(error, data)}
                forceRedirectStrategy={true}
              />
            </div>

          </div>

        </Route>
        <Route>
          {/* Auth must be on the root */}
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

//# ============= REDUX =============
const mapStateToProps = ({ auth_store }) => ({
  isLoading: auth_store.isLoggingIn
});

const mapDispatchToProps = dispatch => {
  return {
    getLogin: payload => dispatch(authActions.GET_LOGIN(payload)),
    showLoading: () => dispatch(authActions.SET_LOGGING_IN()),
    setError: () => dispatch(authActions.SET_UNEXPECTED_ERROR()),
  };
};

//# ============= DEBUG PERFORMANCE PROFILING =============
if (process.env.REACT_APP_HOST_ENV === "debug") {
  //~ Notify in console in debug env if there are any avoidable re-renders
  MicrosoftAuth.whyDidYouRender = true;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MicrosoftAuth);
