import React from "react";

const FIELD_ID = "field-break";

const FieldBreak = ({ value, onChange }) => (
  <div className="field">
    <label htmlFor={FIELD_ID} className="field__label">Break (hours)</label>
    <input
      id={FIELD_ID}
      type="number"
      className="field__input field__input--hours"
      min="0"
      max="8"
      step="0.5"
      value={value}
      onChange={onChange}
    />
  </div>
)

export default FieldBreak;
