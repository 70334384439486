import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import ProjectDetailView from './ProjectDetailView';
import { parseWeekData, getProjectById } from '../../../helpers/dataparsers';
import { 
  weelkIndexStringArr,
  weekSummaryDataObj,
  assignmentObj
} from '../../../helpers/models';
import Config from '../../../configs/Config';

const ProjectDetailContainer = ({ match, weekSummaryData, assignmentData }) => {
  const projectId = parseInt(match.params.projectId);
  const weekIndex = parseInt(match.params.weekIndex);
  const { daySummaryModel, weekSummaryModel } = weekSummaryData[weekIndex];
  const isEditable = (weekSummaryModel.timesheetStatus !== 'Submitted');
  let projectData;
  let isNewProject = false;

  const matchingProject = getProjectById(projectId, daySummaryModel, false);

  if (matchingProject) {
    // project found, use first match
    projectData = parseWeekData(matchingProject);
  } else {
    // no project found, look in assignments
    isNewProject = true;
    const [ matchedAssignment ] = assignmentData.filter(
      assignment => (assignment.projectId === projectId)
    );

    // fake project data from assignment data
    projectData = {
      ...matchedAssignment,
      projectName: matchedAssignment.projectName.replace(/! /, ''),
      weekStartingDate: weekSummaryModel.weekStartingDate,
      weekEndingDate: weekSummaryModel.weekEndingDate,
      days: {}
    };

    // create blank weekdays data
    const mondayDate = dayjs(weekSummaryModel.weekStartingDate);
    for (const [index, weekday] of Config.DaysOfWeek.entries()) {
      projectData.days[weekday.toLowerCase()] = {
        break: 0,
        comments: '',
        date: mondayDate.add(index, 'day').format(),
        end: '',
        hrs: 0,
        start: ''
      };
    }
  }

  return (
    <ProjectDetailView
      weekIndex={weekIndex}
      projectWeekData={projectData}
      isEditable={isEditable}
      isNewProject={isNewProject}
    />
  );
}
ProjectDetailContainer.displayName = 'ProjectDetailContainer';
ProjectDetailContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
      weekIndex: PropTypes.oneOf(weelkIndexStringArr)
    })
  }).isRequired,
  weekSummaryData: PropTypes.arrayOf(
    PropTypes.shape(weekSummaryDataObj)
  ).isRequired,
  assignmentData: PropTypes.arrayOf(
    PropTypes.shape(assignmentObj)
  ).isRequired
};

const mapStateToProps = ({ timesheet_store }) => ({
  weekSummaryData: timesheet_store.weekSummaryData,
  assignmentData: timesheet_store.assignmentData
});

export default connect(mapStateToProps)(ProjectDetailContainer);
