import { combineReducers } from "redux";
import { createEpicMiddleware } from 'redux-observable';
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import AuthStore from "./redux/AuthStore";
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import TimeSheetStore from "./redux/TimeSheetStore";
import PageStateStore from "./redux/PageStateStore";
import rootEpic from './redux/epics';

//# Set up redux store

export const history = createBrowserHistory();
history.location.pathname = '/'; // force back to root

const rootReducer = combineReducers({
  auth_store: AuthStore.reducer,
  timesheet_store: TimeSheetStore.reducer,
  page_state_store: PageStateStore.reducer,
  router: connectRouter(history)
});

const epicMiddleware = createEpicMiddleware();

const middleware = [
  ...getDefaultMiddleware(),
  thunk,
  epicMiddleware,
  routerMiddleware(history)
];

export const store = configureStore({
  reducer: rootReducer,
  middleware
});

epicMiddleware.run(rootEpic);
