import { from } from 'rxjs';
import { switchMap, mergeMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import postSubmitWeekTimesheetApi from '../../api/postSubmitWeekTimesheetApi';
import handleError from './handleError';
import { timesheetActions } from '../TimeSheetStore';
import { pageStateActions } from '../PageStateStore';

const submitWeekEpic = action$ => action$.pipe(
  ofType('timesheet_store/SUBMIT_WEEK'),

  map(({ payload: { accessToken, employeeId, weekEndingDateId } }) => ({
    accessToken,
    employeeId,
    weekEndingDateId
  })),

  switchMap(({ accessToken, employeeId, weekEndingDateId }) =>
    from(postSubmitWeekTimesheetApi(accessToken, employeeId, weekEndingDateId)).pipe(

      mergeMap(() => [
        timesheetActions.UPDATE_WEEK_DATA(accessToken),
        pageStateActions.HIDE_MODAL()
      ]),

      catchError(handleError)
    )
  )
);

export default submitWeekEpic;
