import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import TimeSheetStore from '../../redux/TimeSheetStore';
import { isTimesheetLoading } from '../../redux/Selectors';

import Spinner from './Spinner';
import WeekSummary from './WeekSummary';
import ProjectSummary from './ProjectSummary';
import DaySummary from './DaySummary';
import { DayFormAdd, DayFormEdit, DayFormAddProject } from './DayForm';
import ProjectDetail from './ProjectDetail';

const Timesheet = ({
  accessToken,
  isLoading,
  loadingMessage,
  getUserData,
  getWeekData
}) => {

  useEffect(() => {
    if (accessToken !== null) {
      getUserData(accessToken);
      getWeekData(accessToken);
    }
  }, []);

  if (isLoading) {
    return <Spinner label={loadingMessage} />;
  }

  return (
    <Switch>
      <Route exact path="/" component={WeekSummary} />
      <Route path="/week/:weekIndex" component={WeekSummary} />
      <Route path="/projects/:weekIndex" component={ProjectSummary} />
      <Route exact path="/day/:weekIndex/:dayName" component={DaySummary} />
      <Route exact path="/day/:weekIndex/:dayName/nav" render={
        props => <DaySummary {...props} isStrictNav={true} />
      } />
      <Route exact path="/day/:weekIndex/:dayName/add" render={
        props => <DayFormAdd {...props} />
      } />
      <Route exact path="/day/:weekIndex/:dayName/edit/:projectId" render={
        props => <DayFormEdit {...props} back="day" />
      } />
      <Route exact path="/project/:weekIndex/:projectId" component={ProjectDetail} />
      <Route exact path="/project/:weekIndex/:projectId/edit/:dayName" render={
        props => <DayFormEdit {...props} back="project" />
      } />
      <Route exact path="/project/:weekIndex/:projectId/add/:dayName" render={
        props => <DayFormAddProject {...props} />
      } />
    </Switch>
  );

}

//# ============= REDUX =============
const mapStateToProps = ({ auth_store, timesheet_store }) => {
  return {
    isLoading: isTimesheetLoading(timesheet_store),
    accessToken: auth_store.accessToken,
    loadingMessage: timesheet_store.loadingMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserData: payload => dispatch(TimeSheetStore.actions.GET_USER_DATA(payload)),
    getWeekData: payload => dispatch(TimeSheetStore.actions.GET_WEEK_DATA(payload)),
  };
};

//# ============= DEBUG PERFORMANCE PROFILING =============
if (process.env.REACT_APP_HOST_ENV === 'debug') {
  //~ Notify in console in debug env if there are any avoidable re-renders
  Timesheet.whyDidYouRender = true;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timesheet);
