import { useState }  from 'react';
import { getTotalTime } from '../../../../helpers/dataparsers';

export const useFormValues = (formIntValues) => {
  const [projectValue, setProjectValue] = useState(formIntValues.project);
  const [startValue, setStartValue] = useState(formIntValues.start);
  const [endValue, setEndValue] = useState(formIntValues.end);
  const [breakValue, setBreakValue] = useState(formIntValues.break);
  const [commentsValue, setCommentsValue] = useState(formIntValues.comments);

  const mapFields = {
    project: setProjectValue,
    start: setStartValue,
    end: setEndValue,
    break: setBreakValue,
    comments: setCommentsValue
  };

  const setFormValue = (field, value) => mapFields[field](value);

  const totalTime = getTotalTime(startValue, endValue, breakValue);

  const isValidForm = (
    projectValue !== '' &&
    totalTime >= 0 &&
    startValue !== '' &&
    endValue !== '' &&
    breakValue !== '' &&
    breakValue >= 0
  );

  const formValues = {
    projectValue,
    startValue,
    endValue,
    breakValue,
    commentsValue,
    totalTime,
    isValidForm
  }

  return [ formValues, setFormValue ];
}