import React from "react";

function SessionExpired() {
  //* Reload window
  const reloadSession = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="ui segment placeholder">
        <div className="ui header icon orange">
          <i className="ui icon warning sign" />
          Your active session has expired
        </div>
        <div className="inline">
          <div className="ui button" onClick={() => reloadSession()}>
            Try again
          </div>
        </div>
      </div>
    </>
  );
}

//# ============= DEBUG PERFORMANCE PROFILING =============
if (process.env.REACT_APP_HOST_ENV === "debug") {
  //~ Notify in console in debug env if there are any avoidable re-renders
  SessionExpired.whyDidYouRender = true;
}

export default SessionExpired;
