import { parseWeekData, getWeeksProjects, getProjectById } from '../../../../helpers/dataparsers';

const formMergeProps = ({ weekSummaryData, weekProjectSummaryData, ...ownProps }, mapProps, dispatchProps) => {
  const { weekIndex = '2', dayName = '', projectId = null  } = dispatchProps.match.params;
  const { daySummaryModel, weekSummaryModel } = weekSummaryData[parseInt(weekIndex)]
  const { weekEndingDateId = 0, days = {} } = parseWeekData(weekSummaryModel);

  const weekProjects = getWeeksProjects(weekProjectSummaryData, parseInt(weekIndex));
  const currentProjectIds = weekProjects.map(project => project.projectId);
  const isNewWeek = (daySummaryModel.length === 0);

  let projectData = null;
  if (projectId) {
    projectData = parseWeekData(getProjectById(projectId, daySummaryModel));
  }

  return {
    weekIndex,
    dayName,
    projectId,
    projectData,
    weekEndingDateId,
    dayData: days[dayName],
    currentProjectIds,
    daySummaryModel,
    isNewWeek,
    ...ownProps,
    ...mapProps,
    ...dispatchProps
  };
};

export default formMergeProps;
