import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import getIsUserAuthenticatedApi from '../../api/getIsUserAuthenticatedApi';
import { authActions } from '../AuthStore';
import handleError from './handleError';

const getLoginEpic = action$ => action$.pipe(
  ofType('auth_store/GET_LOGIN'),

  map(({ payload }) => ({
    accessToken: payload
  })),

  switchMap(({ accessToken }) =>
    from(getIsUserAuthenticatedApi(accessToken)).pipe(

      map(({ data }) => ({
        isUserAuthenticated: data.isUserAuthenticated
      })),

      map(({ isUserAuthenticated }) => authActions.SET_SIGN_IN(isUserAuthenticated)),

      catchError(handleError)
    )
  )
);

export default getLoginEpic;
