import React from "react";
import { WaveSpinner } from "react-spinners-kit";

//* Render a loading spinner on the screen
const Spinner = ({ label = 'Loading' }) => (
  <div className="spinner">
    <WaveSpinner size={35} color="#027ad0" />
    <p className="spinner__label">{label}</p>
  </div>
);

export default Spinner;
