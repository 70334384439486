import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WeekHeader from '../Common/WeekHeader';
import ProjectView from './ProjectView';
import SubmitHoursButton from '../Common/SubmitHoursButton';
import SubmitHoursModal from '../Common/SubmitHoursModal';
import { weekDataToArray } from '../../../helpers/dataparsers';
import { convertSQLDate } from '../../other/Dates';
import { weelkIndexArr } from '../../../helpers/models';

const ProjectSummaryView = ({
  weekIndex,
  projects,
  weekData,
  expectedUserHours,
  onSubmit
}) => {
  const LIST_CUTOFF_NUM = 6;
  const [isFullShown, setIsFullShown] = useState(false);
  const projectsList = isFullShown ? projects : projects.slice(0, LIST_CUTOFF_NUM);
  const toggleFullList = () => { setIsFullShown(!isFullShown); }

  return (
    <section className="content">

      <WeekHeader
        weekIndex={weekIndex}
        type="projects"
        startingDate={convertSQLDate(weekData.weekStartingDate)}
        endingDate={convertSQLDate(weekData.weekEndingDate)}
        totalHours={weekData.weekLogged}
        timesheetStatus={weekData.timesheetStatus}
        weekData={weekDataToArray(weekData)}
        expectedUserHours={expectedUserHours}
      />

      <ul className="summary-list summary-list--borderless">
        {projectsList.map(project => <ProjectView
          key={`project-${project.projectId}`}
          weekIndex={weekIndex}
          {...project}
        />)}
      </ul>

      {(projects.length > LIST_CUTOFF_NUM) && 
        <button 
          type="button"
          className={classNames('show-btn', { 'show-btn--open': isFullShown })}
          onClick={toggleFullList}
        >
          { isFullShown ? 'Show Less' : 'Show More' }
        </button>
      }

      {(projects.length === 0) && <div className="page-notice">No projects</div>}

      <SubmitHoursButton
        weekData={weekData}
        expectedUserHours={expectedUserHours}
        onSubmit={onSubmit}
      />

      <SubmitHoursModal weekIndex={weekIndex} />

    </section>
  );
};
ProjectSummaryView.displayName = 'ProjectSummaryView';
ProjectSummaryView.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  expectedUserHours: PropTypes.number.isRequired,
  project: PropTypes.arrayOf(PropTypes.shape()),
  weekData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ProjectSummaryView;
