import { combineEpics } from 'redux-observable';
import getLoginEpic from './getLoginEpic';
import getUserDataEpic from './getUserDataEpic';
import getWeekDataEpic from './getWeekDataEpic';
import setSignOutEpic from './setSignOutEpic';
import submitDayEpic from './submitDayEpic';
import submitWeekEpic from './submitWeekEpic';

const rootEpic = combineEpics(
  // auth
  getLoginEpic,
  setSignOutEpic,

  // timesheet
  getUserDataEpic,
  getWeekDataEpic,
  submitDayEpic,
  submitWeekEpic
);

export default rootEpic;
