import React from "react";
import { connect } from "react-redux";
import WeekSummaryView from "./WeekSummaryView";
import PageStateStore from "../../../redux/PageStateStore";
import { getExpectedUserHours } from "../../../redux/Selectors";

const DEFAULT_WEEK_INDEX = 2;

const WeekSummaryContainer = ({
  match,
  weekSummaryData,
  expectedUserHours,
  showModal
}) => {
  const weekIndex = parseInt(match.params.weekIndex || DEFAULT_WEEK_INDEX);

  const handleSubmit = () => {
    showModal();
  }

  return (
    <WeekSummaryView
      weekIndex={weekIndex}
      weekData={weekSummaryData[weekIndex].weekSummaryModel}
      expectedUserHours={expectedUserHours}
      onSubmit={() => { handleSubmit(); }}
    />
  );
}

const mapStateToProps = ({ timesheet_store }) => ({
  weekSummaryData: timesheet_store.weekSummaryData,
  expectedUserHours: getExpectedUserHours(timesheet_store)
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch(PageStateStore.actions.SHOW_MODAL())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeekSummaryContainer);
