import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavLink = ({ url, icon, label }) => {
  if (url === '') {
    return (
      <span className="page-header__nav page-header__nav--disabled">
        <i className={`icon angle ${icon}`} />
      </span>
    );
  }

  return (
    <Link
      to={url}
      className="page-header__nav"
      role="navigation"
      aria-label={label}
    >
      <i className={`icon angle ${icon}`} />
    </Link>
  );
}
NavLink.displayName = 'NavLink';
NavLink.propTypes = {
  url: PropTypes.string,
  icon: PropTypes.oneOf([ 'left', 'right' ]).isRequired,
  label: PropTypes.string
};
NavLink.defaultProps = {
  url: '',
  label: ''
};

export default NavLink;