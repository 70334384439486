import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from './Modal';
import { pageStateActions } from '../../../redux/PageStateStore';
import { timesheetActions } from '../../../redux/TimeSheetStore';
import { parseWeekSummaryData } from '../../../helpers/dataparsers';
import { weelkIndexArr, weekSummaryDataObj } from '../../../helpers/models';

const DAY_LABEL_MAP = {
  monday: 'Mon',
  tuesday: 'Tues',
  wednesday: 'Wed',
  thursday: 'Thur',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun'
}

const SubmitHoursModal = ({
  weekIndex,
  weekSummaryData,
  accessToken,
  hideModal,
  submit
}) => {
  const data = parseWeekSummaryData(weekSummaryData[weekIndex].daySummaryModel);
  const { weekLogged: totalHours, employeeId, weekEndingDateId } = weekSummaryData[weekIndex].weekSummaryModel;

  const onCancel = () => {
    hideModal();
  }

  const onConfirm = () => {
    submit(employeeId, weekEndingDateId, accessToken);
  }

  const renderProject = (project, index) => {
    const noBlankDaysFilter = day => day[1].hrs > 0;

    return (
      <li key={`project-${index}`} className="submission-summary__project">
        <span className="submission-summary__title">{project.projectName}</span>
        <ul className="submission-summary__days">
          {Object.entries(project.days)
            .filter(noBlankDaysFilter)
            .map(renderProjectDay)}
        </ul>
      </li>
    );
  }

  const renderProjectDay = (day, index) => {
    const [ dayKey, { start, end, break: breakHr, hrs } ] = day;

    return (
      <li key={`project-day-${index}`} className="submission-summary__day">
        {DAY_LABEL_MAP[dayKey]}, {start} - {end}, {breakHr} break, {hrs} hours
      </li>
    );
  }

  return (
    <Modal>
      <>
        <h2>Ready to submit hours?</h2>

        <ul className="submission-summary">
          {data.map(renderProject)}
        </ul>

        <p><strong>Total hours:</strong> {totalHours}</p>

        <div className="actions">
          <button type="button" className="btn btn--back" onClick={() => { onCancel(); }}>Cancel</button>
          <button type="button" className="btn" onClick={() => { onConfirm(); }}>Confirm</button>
        </div>
      </>
    </Modal>
  )
}
SubmitHoursModal.displayName = 'SubmitHoursModal';
SubmitHoursModal.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  weekSummaryData: PropTypes.arrayOf(PropTypes.shape(weekSummaryDataObj)).isRequired,
  accessToken: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

const mapStateToProps = ({ timesheet_store, auth_store }) => ({
  weekSummaryData: timesheet_store.weekSummaryData,
  accessToken: auth_store.accessToken
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(pageStateActions.HIDE_MODAL()),
  submit: (employeeId, weekEndingDateId, accessToken) => dispatch(timesheetActions.SUBMIT_WEEK(
    { employeeId, weekEndingDateId, accessToken }
  ))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitHoursModal);
