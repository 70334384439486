import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { formatHoursLabel } from '../../../helpers/formatters';
import { weelkIndexArr, weekDaysLowerArr } from '../../../helpers/models';

const DayView = ({ weekIndex, day, date, logged }) => {
  const dateParsed = dayjs(date);
  const dayClasses = classNames(
    'summary-list__item',
    'day-item',
    { 'day-item--today': dayjs().isSame(dateParsed, 'day') }
  );

  return (
    <li className={dayClasses}>
      <Link
        to={`/day/${weekIndex}/${day}`}
        className="summary-list__content summary-list__content--link"
      >
        <div>
          <div className="day-item__weekday">{dateParsed.format("dddd")}</div>
          <div className="day-item__date">{dateParsed.format("Do MMMM")}</div>
        </div>
        <div className="day-item__hrs">{formatHoursLabel(logged)}</div>
      </Link>
    </li>
  );
};
DayView.displayName = 'DayView';
DayView.propTypes = {
  weekIndex: PropTypes.oneOf(weelkIndexArr).isRequired,
  day: PropTypes.oneOf(weekDaysLowerArr).isRequired,
  date: PropTypes.string.isRequired,
  logged: PropTypes.number.isRequired
}

export default DayView;
